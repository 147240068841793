<template>
    <div class="app-body">
        <div class="a-flex-rsbc a-ptb-6">
            <span class="a-fs-16 a-fw-700">客诉处理列表</span>
        </div>
        <el-card class="el-main">
            <div id="leSearch">
                <div class="a-flex-rfsc">
                    <div class="a-flex-cfsc complaint-item complaint-item-wait" @click="handleClickCount('PENDING')">
                        <span class="title">待处理</span>
                        <div class="a-flex-rfsbl a-mtb-12">
                            <span class="waitNum">{{ pendingNum || 0 }}</span>
                            <span class="waitText">单</span>
                        </div>
                        <div class="a-flex-rfsc">
                            <span class="overtimeText">已超时</span>
                            <span class="overtimeNum">{{ pendingOverTimeNum || 0 }}</span>
                            <span class="overtimeText">单</span>
                        </div>
                    </div>
                    <div class="a-flex-cfsc complaint-item complaint-item-doing" @click="handleClickCount('PROCESSING')">
                        <span class="title">处理中</span>
                        <div class="a-flex-rfsbl a-mtb-12">
                            <span class="waitNum">{{ processingNum || 0 }}</span>
                            <span class="waitText">单</span>
                        </div>
                        <div class="a-flex-rfsc">
                            <span class="overtimeText">已超时</span>
                            <span class="overtimeNum">{{ processingOverTimeNum || 0 }}</span>
                            <span class="overtimeText">单</span>
                        </div>
                    </div>
                </div>
                <le-search-form @reset="handlerRest" @search="handlerSearch" >
                    <le-date-range 
                        label="日期" 
                        ref="dateRange" 
                        :minDate.sync="pageParam.params.complaintStartTime" 
                        :maxDate.sync="pageParam.params.complaintEndTime" 
                        valueFormat="yyyy-MM-dd HH:mm:ss" />
                    <le-input label="订单编号" v-model="pageParam.params.orderSn" />
                    <le-input label="手机号" v-model="pageParam.params.mobile" />
                    <le-select-local-search label="投诉状态" v-model="pageParam.params.complaintState" :options="complaintStatusDic" />
                    <le-company-under-select placeholder="请选择（可输入搜索）" label="商户" v-model="pageParam.params.companyId"></le-company-under-select>
                </le-search-form>
            </div>
            <le-pagview ref="complaintListPage" @setData="setTableData" :pageParam="pageParam" :isFixed="true" :tableRef="$refs.complaintList" :pageSizeDefault='20'>
                <el-table 
                    ref="complaintList" 
                    :data="tableData" 
                    :highlight-current-row="true" 
                    v-sticky="{ top: 0, parent:'.el-card__body' }" 
                    style="width: 100%">
                    <el-table-column prop="nameId" label="订单号" min-width="120">
                        <template slot-scope="{ row }">
                            <span class="font-point a-c-blue" @click="toOrderInfo(row)">{{ row.orderSn || '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="transactionId" label="支付单号" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.transactionId || '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="companyName" label="商户" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.companyName || '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="problemDescription" label="问题描述" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.problemDescription || '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="unitPriceId" label="投诉类型" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.problemType | initDic(problemTypeDic) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="stationId" label="订单金额" min-width="120">
                        <template slot-scope="{ row }">
                            <div class="a-flex-cfsfs" style="align-items: flex-start">
                                <span>￥{{ util.numFormat(row.amount) }}</span>
                                <span class="a-c-blue" v-if="row.complaintFullRefunded">（已全额退款）</span>
								<span class="a-c-blue" v-else-if="row.refundAmount">{{ '（已退款￥'+util.numFormat(row.refundAmount) +'）' }}</span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="ammeterId" label="申请退款金额" min-width="120">
                        <template slot-scope="{ row }">
                            <span>￥{{ util.numFormat(row.applyRefundAmount) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="ammeterId" label="投诉描述" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.complaintDetail || '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="ammeterId" label="联系人" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.payerPhone || '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="ammeterId" label="投诉次数" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.userComplaintTimes || '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="ammeterId" label="投诉时间" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.complaintTime || '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="ammeterId" label="投诉单状态" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.complaintState | initDic(complaintStatusDic) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="pid" label="操作" width="150" fixed="right">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" content="回复" placement="top">
                                <img src="../../assets/icon/option-msg.png" class="a-wh-16" @click="handleApply(scope.row)" />
                            </el-tooltip>
                            <el-tooltip class="item a-ml-12" effect="dark" content="详情" placement="top">
                                <img src="../../assets/icon/option-detail.png" class="a-wh-16" @click="toComplaintInfo(scope.row)" />
                            </el-tooltip>
                            <el-tooltip 
                                class="item a-ml-12" 
                                effect="dark" 
                                content="驳回" 
                                placement="top"
                                v-if="scope.row.complaintState != 'PROCESSED' && scope.row.problemType == 'REFUND' && scope.row.userComplaintTimes == 1">
                                <img src="../../assets/icon/option-refuse.png" class="a-wh-16" @click="handleReject(scope.row)" />
                            </el-tooltip>
                            <el-tooltip class="item a-ml-12" effect="dark" content="发起退款" placement="top">
                                <img src="../../assets/icon/option-refund.png" class="a-wh-16" @click="handleRefound(scope.row)" v-if="!scope.row.complaintFullRefunded" />
                            </el-tooltip>
                            <el-tooltip class="item a-ml-12" effect="dark" content="处理完成" placement="top" v-if="scope.row.canComplete && scope.row.complaintState != 'PROCESSED'">
                                <img src="../../assets/icon/option-agree.png" class="a-wh-16" @click="handleComplete(scope.row)" />
                            </el-tooltip>
                            
                        </template>
                    </el-table-column>
                </el-table>
            </le-pagview>
        </el-card>
        <complaint-apply ref="complaintApply" @handlerSuccess='handlerApplySuccess'></complaint-apply>
        <complaint-reject ref="complaintReject" @handlerSuccess='handlerRejectSuccess'></complaint-reject>
        <cabinet-order-refund ref="cabinetOrderRefund" @oprateFinish="refundFinish"></cabinet-order-refund>
        <package-order-refound ref="packageOrderRefound" @oprateFinish="refundFinish"></package-order-refound>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex';
    import util from '../../../src/utils/util'
    import complaintApply from "./child/complaint-apply.vue";
    import complaintReject from "./child/complaint-reject.vue";
    import CabinetOrderRefund from '../order/child/cabinetOrderRefund.vue'
    import PackageOrderRefound from '../order/child/packageOrderRefound.vue'
    export default {
        components: {
            complaintApply,
            CabinetOrderRefund,
            PackageOrderRefound,
            complaintReject
        },
        data () {
            return {
                util: util,
                tableData: [],
                pageParam: null,
                choiceDate: '',
                pickerOptions: {
                    onPick: ({ maxDate, minDate }) => {
                        this.choiceDate = minDate.getTime()
                        if (maxDate) this.choiceDate = ''
                    },
                    disabledDate: (time) => {
                        if (this.choiceDate) {
                            const one = 92 * 24 * 3600 * 1000
                            const minTime = this.choiceDate - one
                            const maxTime = this.choiceDate + one
                            return time.getTime() < minTime || time.getTime() > maxTime
                        }else{
                            return ''
                        }
                    },
                },
                complaintStatusDic: [],//投诉状态字典
                problemTypeDic: [],//投诉类型字典
                pendingNum: 0,
                pendingOverTimeNum: 0,
                processingNum: 0,
                processingOverTimeNum: 0,
                launchRefundDay: 3,//发起退款预计时间（天）
            }
        },
        computed:{
            ...mapState({
                company: state => state.company.company,
            }),
        },
        created () {
            this.$getDic('complaintState','select').then(res=>{ this.complaintStatusDic = res; })
            this.$getDic('complaintProblemType','select').then(res=>{ this.problemTypeDic = res; })
            this.getComplaintCount()
            this.pageParam = {
                url: this.$Config.apiUrl.complaintPage,
                method: "post",
                params: {
                    companyId: '',
                    complaintStartTime: this.$getDay.getToday() + ' 00:00:00',
                    complaintEndTime: this.$getDay.getToday() + ' 23:59:59',
                    complaintState: '',
                    mobile: '',
                    orderSn: ''
                },
                freshCtrl: 1,
            }
        },
        activated () {
            this.pageParam.freshCtrl++;
            this.getComplaintCount()
        },
        methods:{
            //获取列表
            setTableData(data) {
                this.tableData = data
            },
            handlerRest() {
                this.pageParam.params = {
                    companyId: '',
                    complaintStartTime: '',
                    complaintEndTime: '',
                    complaintState: '',
                    mobile: '',
                    orderSn: ''
                };
                this.handlerSearch()
            },
            handlerSearch() {
                this.$refs['complaintListPage'].pageNum = 1
                this.pageParam.freshCtrl++;
                this.getComplaintCount()
            },
            handleComplete (datas) {
                this.$Axios._post({
                    url: this.$Config.apiUrl.complaintComplete,
                    method: "post",
                    params: {
                        complaintId: datas.complaintId,
						complaintMchId: datas.complaintedMchid,
						mchId: datas.mchId
                    }
                })
                .then(res=>{
                    if (res.result.code == 0) {
                        this.pageParam.freshCtrl++;
                        this.$message.success('操作成功')
                    } else {
                        this.$message.error(res.result.message)
                    }
                })
                .catch(err=>{
                    console.log(err);
                })
            },
            handleApply (datas) {
                this.$refs['complaintApply'].form.complainted_mchid = datas.complaintedMchid
                this.$refs['complaintApply'].form.mchId = datas.mchId
                this.$refs['complaintApply'].form.response_content = ''
                this.$refs['complaintApply'].form.response_images = []
                this.$refs['complaintApply'].form.response_medias = []
                this.$refs['complaintApply'].form.complaintId = datas.complaintId
                this.$refs['complaintApply'].dialogVisible = true
            },
            handleReject (datas) {
                this.$refs['complaintReject'].form.action = 'REJECT'
                this.$refs['complaintReject'].form.mchId = datas.mchId
                this.$refs['complaintReject'].form.reject_reason = ''
                this.$refs['complaintReject'].form.reject_media_list = []
                this.$refs['complaintReject'].form.reject_img_list = []
                this.$refs['complaintReject'].form.complaintId = datas.complaintId
                this.$refs['complaintReject'].form.remark = ''
                this.$refs['complaintReject'].dialogVisible = true
            },
            async handleRefound (row) {
                let datas
                if(row.canRefund) {
                    await this.handleUpdateRefound(row)
                }
                switch (row.orderType) {
                    case 1:
                        // 套餐订单
                        datas = await this.getPackageOrderInfo(row)
                        if(datas) {
                            this.$refs['packageOrderRefound'].id = datas.id
                            this.$refs['packageOrderRefound'].form.refundAmount = Number(((datas.payAmount - datas.refundAmount)/100).toFixed(2))
                            this.$refs['packageOrderRefound'].form.returnGoods = 1
                            this.$refs['packageOrderRefound'].form.payAmount = datas.payAmount
                            this.$refs['packageOrderRefound'].orderSn = datas.orderSn
                            try {
                                this.$refs['packageOrderRefound'].form.packageType = JSON.parse(datas.goodsInfo).type
                                this.$refs['packageOrderRefound'].form.packgeSoybean = JSON.parse(datas.goodsInfo).promotionPrice || 0
                                this.$refs['packageOrderRefound'].form.packgeSoybeanGift = JSON.parse(datas.goodsInfo).giftAmount || 0
                            } catch (error) {
                                
                            }
                            this.$refs['packageOrderRefound'].form.refundDiscountVoList = datas.orderDiscountInfoList.filter(res=>{
                                return res.discountType != 3 && res.discountType != 1 && res.discountType != 6 //退款时将vip优惠剔除出去
                            }).map(item=>{
                                return {
                                    refundAmount: Number(((Number(item.discountPayAmount || 0) - Number(item.refundPayAmount || 0))/100).toFixed(2)),
                                    originRefundAmount: Number(((Number(item.discountPayAmount || 0) - Number(item.refundPayAmount || 0))/100).toFixed(2)),
                                    discountType: item.discountType
                                }
                            })
                            this.$refs['packageOrderRefound'].dialogVisible = true
                        }
                        break;
                    case 2:
                        // 充电桩订单
                        datas = await this.getOrderInfoById(row)
                        if(datas) {
                            this.$refs['cabinetOrderRefund'].id = datas.orderId //订单id
                            this.$refs['cabinetOrderRefund'].orderType = datas.orderType //订单类型
                            this.$refs['cabinetOrderRefund'].originRefundAmount = Number((((datas.payAmount || 0) - (datas.refundAmount || 0))/100).toFixed(2))
                            this.$refs['cabinetOrderRefund'].form.refundAmount = Number((((datas.payAmount || 0) - (datas.refundAmount || 0))/100).toFixed(2))
                            this.$refs['cabinetOrderRefund'].form.refundDiscountVoList = datas.orderDiscountInfoList.filter(res=>{
                                return res.discountType != 3 && res.discountType != 1 && res.discountType != 6 //退款时将vip优惠剔除出去
                            }).map(item=>{
                                return {
                                    refundAmount: Number(((Number(item.discountPayAmount || 0) - Number(item.refundPayAmount || 0))/100).toFixed(2)),
                                    originRefundAmount: Number(((Number(item.discountPayAmount || 0) - Number(item.refundPayAmount || 0))/100).toFixed(2)),
                                    discountType: item.discountType
                                }
                            })
                            // this.$refs['cabinetOrderRefund'].superOrderItem = datas.superOrderItem //安心充订单
                            if(datas.superOrderItem) {
                                this.$refs['cabinetOrderRefund'].superOrderItem = datas.superOrderItem.find(res=>{
                                    return res.goodsType == 2
                                }) //安心充订单
                            }
                            this.$refs['cabinetOrderRefund'].dialogVisible = true
                        }
                        break;
                    case 3:
                        // 水机订单
                        this.$confirm(`是否确认为订单：${row.orderSn}退款？`,'温馨提示')
                        .then(_=>{
                            this.$Axios._post({
                                url: this.$Config.apiUrl.waterOrderRefund + '/' + row.orderSn,
                                method: "post",
                            })
                            .then(res=>{
                                if (res.result.code == 0) {
                                    this.$message.success('操作成功')
                                    this.pageParam.freshCtrl++;
                                }
                            })
                            .catch(err=>{ })
                        })
                        .catch(_=>{})
                        break;
                    case 4:
                    case 5:
                        // 充电柜、充电舱订单
                        datas = await this.getCabinetOrderInfo(row)
                        if(datas) {
                            this.$refs['cabinetOrderRefund'].id = datas.id //订单id
                            this.$refs['cabinetOrderRefund'].orderType = datas.orderType //订单类型
                            this.$refs['cabinetOrderRefund'].originRefundAmount = Number((((datas.payAmount || 0) - (datas.refundAmount || 0))/100).toFixed(2))
                            this.$refs['cabinetOrderRefund'].form.refundAmount = Number((((datas.payAmount || 0) - (datas.refundAmount || 0))/100).toFixed(2))
                            this.$refs['cabinetOrderRefund'].form.refundDiscountVoList = datas.orderDiscountInfoList.filter(res=>{
                                return res.discountType != 3 && res.discountType != 1 && res.discountType != 6 //退款时将vip优惠剔除出去
                            }).map(item=>{
                                return {
                                    refundAmount: Number(((Number(item.discountPayAmount || 0) - Number(item.refundPayAmount || 0))/100).toFixed(2)),
                                    originRefundAmount: Number(((Number(item.discountPayAmount || 0) - Number(item.refundPayAmount || 0))/100).toFixed(2)),
                                    discountType: item.discountType
                                }
                            })
                            if(datas.superOrderItem) {
                                this.$refs['cabinetOrderRefund'].superOrderItem = datas.superOrderItem.find(res=>{
                                    return res.goodsType == 2
                                }) //安心充订单
                            }
                            this.$refs['cabinetOrderRefund'].dialogVisible = true
                        }
                        break;
                    default:
                        break;
                }
            },
            toOrderInfo (datas) {
                switch (datas.orderType){
					case 1:
						// 套餐订单
                        if (!this.$_has(72)) return
						this.$router.push({
                            path:'/order/package-order-info',
                            query: {
                                id: datas.orderSn
                            }
                        })
						break;
					case 2:
						// 充电订单
                        if (!this.$_has(4)) return
						this.$router.push({
                            path: '/order/order-detail',
                            query: {
                                'orderId': datas.orderSn
                            }
                        })
						break;
					case 4:
					case 5:
						// 充电柜订单
                        if (!this.$_has(101)) return
						this.$router.push({
                            path:'/order/chargeCabinet-order-info',
                            query: {
                                orderId: datas.orderSn,
                                orderSn: datas.orderSn
                            }
                        })
						break;
					default:
						break;
				}
            },
            // 获取客诉统计
            getComplaintCount () {
                this.$Axios._post({
                    url: this.$Config.apiUrl.complaintCount,
                    params: {
                        // companyId: this.company.id
                    }
                }).then((res) => {
                    if (res.result.code == 0) {
                        this.pendingNum = res.result.data.pendingNum || 0
                        this.pendingOverTimeNum = res.result.data.pendingOverTimeNum || 0
                        this.processingNum = res.result.data.processingNum || 0
                        this.processingOverTimeNum = res.result.data.processingOverTimeNum || 0
                    }
                })
            },
            handleClickCount (status) {
                this.pageParam.params.complaintState = status
                this.pageParam.params.complaintStartTime = ''
                this.pageParam.params.complaintEndTime = ''
                this.handlerSearch()
            },
            refundFinish () {
                this.pageParam.freshCtrl++;
            },
            // 查看客诉详情
            toComplaintInfo (datas) {
                this.$router.push({
                    path: '/userMGT/userMGT-complaint-details',
                    query:{
                        id: datas?datas.complaintId:'',
                        mchId: datas?datas.mchId:'',
                    }
                })
            },
            handlerApplySuccess () {
                this.pageParam.freshCtrl++;
            },
            handlerRejectSuccess () {
                this.pageParam.freshCtrl++;
            },
            // 更新退款状态
            handleUpdateRefound (datas) {
                return new Promise((resolve, reject)=>{
                    this.$Axios._post({
                        url: this.$Config.apiUrl.complaintUpdateRefund,
                        params: {
                            action: 'APPROVE',//操作类型
                            mchId: datas.mchId,
                            complaintId: datas.complaintId,
                            launchRefundDay: this.launchRefundDay
                        },
                    })
                    .then(res => {
                        if(res.result.code == 0){
                            resolve()
                        }else{
                            this.$message.error(res.result.message)
                            reject()
                        }
                    })
                    .catch((error) => {
                        this.$message.error(error.result.message)
                        reject()
                    });
                })
            },
            // 获取套餐订单详情
            getPackageOrderInfo (datas) {
                return new Promise((resolve, reject) => {
                    this.$Axios._get({
                        url: this.$Config.apiUrl.superOrderDetails,
                        method: "get",
                        params: {
                            id: datas.orderSn
                        }
                    }).then(res => {
                        if(res.result.code == 0){
                            resolve(res.result.data)
                        }else{
                            this.$message.error(res.result.message)
                            reject()
                        }
                    }).catch(err=>{
                        this.$message.error(err.result.message)
                        reject()
                    })
                })
            },
            // 查看充电柜订单详情
            getCabinetOrderInfo (datas) {
                return new Promise((resolve, reject) => {
                    this.$Axios._get({
                        url: this.$Config.apiUrl.cabinetOrderInfo,
                        params: {
                            boxOrderId: datas.orderSn
                        },
                    }).then((res) => {
                        if(res.result.code == 0){
                            resolve(res.result.data)
                        }else{
                            this.$message.error(res.result.message)
                            reject()
                        }
                    }).catch(err=>{
                        this.$message.error(err.result.message)
                        reject()
                    })
                })
            },
            //获取充电桩订单详情
            getOrderInfoById(datas) {
                return new Promise((resolve, reject) => {
                    this.$Axios._get({
                        url: this.$Config.apiUrl.getOrderInfoById,
                        params: {
                            orderId: datas.orderSn
                        },
                    }).then((res) => {
                        if(res.result.code == 0){
                            resolve(res.result.data)
                        }else{
                            this.$message.error(res.result.message)
                            reject()
                        }
                    }).catch(err=>{
                        this.$message.error(err.result.message)
                        reject()
                    })
                })
            },
            exportfile () {
                // this.$exfile({
                //     code: 15,
                //     fileName: this.pageParam.params.searchStartTime + ' 至 ' + this.pageParam.params.searchEndTime + ' 省份经营分析',
                //     startTime: this.pageParam.params.searchStartTime,
                //     endTime: this.pageParam.params.searchEndTime,
                //     params: this.pageParam.params
                // })
            }
        }
    }
</script>

<style lang="scss" scoped>
.complaint-item{
    margin-right: 20px;
    width: 400px;
    padding: 18px 0;
    border-radius: 12px;
    .title{
        font-size: 16px;
        font-weight: 400;
    }
    .waitNum{
        font-size: 40px;
        font-weight: 500;
        // color: #007AFF;
        padding: 0 6px;
    }
    .waitText{
        font-size: 16px;
        font-weight: 400;
        // color: #007AFF;
    }
    .overtimeText{
        font-size: 14px;
        color: #666666;
    }
    .overtimeNum{
        font-size: 20px;
        color: #F56C6C;
        padding: 0 6px;
    }
}
.complaint-item-wait{
    background: #EAF4FF;
    // border: 2rpx solid #6CB1FF;
    color: #007AFF;
}
.complaint-item-doing{
    background: #ECFFFA;
    // border: 2rpx solid #6CCFB7;
    color: #0FBC93;
}
.s-btn-add{
    width: 135px;
}
// /deep/ .s-search-label{
//     width: 100px
// }
/deep/ .el-table__body-wrapper::-webkit-scrollbar {
    /*width: 0;宽度为0隐藏*/
    width: 0 !important;
    height: 0 !important;
}
/deep/ .el-table__fixed, /deep/ .el-table__fixed-right{
    height: 100% !important;
}
.stationTag{
    padding: 0px 6px;
    background: #f4f4f5;
    color: #909399;
    border: 1px solid #e9e9eb;
    border-radius: 3px;
    margin-right: 4px;
    margin-bottom: 4px;
    font-size: 12px;
    word-break: keep-all;
}
</style>